import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) {
  }

  btnClickNosotros() {
    this.router.navigateByUrl("/nosotros");
  }

  btnClickServicios() {
    this.router.navigateByUrl("/servicios");
  }

  btnClickContacto() {
    this.router.navigateByUrl("/contacto");
  }

  btnClickVentas() {
    this.router.navigateByUrl("/ventas");
  }

  ngOnInit() {
  }

}
