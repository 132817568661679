import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-divas-solares',
  templateUrl: './divas-solares.component.html',
  styleUrls: ['./divas-solares.component.css']
})
export class DivasSolaresComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
