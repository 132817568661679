import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instalacion',
  templateUrl: './instalacion.component.html',
  styleUrls: ['./instalacion.component.css']
})
export class InstalacionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
