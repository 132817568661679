import { Routes, RouterModule} from "@angular/router";
import { NgModule } from "@angular/core";
import { VentasComponent } from "../ventas.component";
import { BombasComponent } from "./bombas/bombas.component";
import { MotoresComponent } from "./motores/motores.component";
import { CableadoComponent } from "./cableado/cableado.component";
import { TuberiaComponent } from "./tuberia/tuberia.component";
import { DivasSolaresComponent } from "./divas-solares/divas-solares.component";
import { InstalacionComponent } from "./instalacion/instalacion.component";
import { ReparacionComponent } from "./reparacion/reparacion.component";

const ventasRouter: Routes = [
  { path: 'ventas', component: VentasComponent, children: [
    { path: '', redirectTo: '/ventas/bombas', pathMatch: 'full' },
    { path: 'bombas', component: BombasComponent },
    { path: 'motores', component: MotoresComponent },
    { path: 'cableado', component: CableadoComponent },
    { path: 'tuberia', component: TuberiaComponent },
    { path: 'divassolares', component: DivasSolaresComponent },
    { path: 'instalacion', component: InstalacionComponent },
    { path: 'reparacion', component: ReparacionComponent },
  ]}
]

@NgModule({
  imports: [
    RouterModule.forChild(ventasRouter)
  ],
  exports: [
    RouterModule
  ]
})

export class VentasRoutingModule {}
