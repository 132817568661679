import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VentasRoutingModule } from './menu/ventas-routing.module';

import { BombasComponent } from './menu/bombas/bombas.component';
import { MotoresComponent } from './menu/motores/motores.component';
import { CableadoComponent } from './menu/cableado/cableado.component';
import { TuberiaComponent } from './menu/tuberia/tuberia.component';
import { DivasSolaresComponent } from './menu/divas-solares/divas-solares.component';
import { VentasComponent } from './ventas.component';
import { ReparacionComponent } from './menu/reparacion/reparacion.component';
import { InstalacionComponent } from './menu/instalacion/instalacion.component';
import { ImagenModalComponent } from './menu/imagen-modal/imagen-modal.component';

@NgModule({
  imports: [
    CommonModule,
    VentasRoutingModule
  ],
  declarations: [
    VentasComponent,
    BombasComponent,
    MotoresComponent,
    CableadoComponent,
    TuberiaComponent,
    DivasSolaresComponent,
    ReparacionComponent,
    InstalacionComponent,
    ImagenModalComponent,
  ],
  exports: [
    VentasComponent,
    BombasComponent,
    MotoresComponent,
    CableadoComponent,
    TuberiaComponent,
    DivasSolaresComponent,
    ReparacionComponent,
    InstalacionComponent,
  ]
})
export class VentasModule { }
